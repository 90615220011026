<template>
  <div>
    <left-and-right>
      <div slot="leftDiv">
        <div class="article" v-for="count in data.records" :key="count.index">
          <div class="date">
            <div class="day">{{count.day}}</div>
            <div class="year">{{count.month}} {{count.year}}</div>
          </div>
          <div class="content" @click="toDetails(count.id)">
            <h3>{{ count.title }}</h3>
            <h4>来源：{{count.source}}</h4>
            <p>{{count.brief}}</p>
            <a>
              阅读全文→
            </a>
          </div>
        </div>
        <pagination v-on:toPage="toPage" :this-page="current" :pages="data.pages"></pagination>
      </div>
      <div slot="rightDiv">
        <card ref="card0" list-style="number"></card>
        <card ref="card"></card>
        <img src="../../assets/image/web.png">
      </div>
    </left-and-right>
  </div>
</template>
<script>

import leftAndRight from "@/components/public/leftAndRight";
import pagination from "@/components/public/pagination";
import Card from "@/components/public/card";

export default {
  name: "newDeal",
  data() {
    return {
      data:{},
      current:1,
    }
  },
  components: {
    Card,
    leftAndRight,
    pagination
  },
  mounted() {
    this.getDataList()
  },
  methods:{
    getDataList() {
      this.$http.get('/safe/getArticleList', {
        params: {
          columnName: '地方政策',
          belong: 1,
          current: this.current,
          size: 5
        }
      }).then((res) => {
        const monthEnglish=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Spt","Oct","Nov","Dec"]

        let forms=res.data.records
        for(let i in forms){
          let time = forms[i].time;
          let date = new Date(time);
          forms[i].year=this.$constContent.dateFormat("YYYY", date)
          forms[i].month=monthEnglish[date.getMonth()+1]
          forms[i].day=this.$constContent.dateFormat('dd',date)

        }
        this.data=res.data
      })


      const list = ["热门政策", "往期精选"]

      for (let index in list) {
        const ths = this
        this.$http.get('/safe/getIndexInformation', {
          params: {
            name: list[index],
          }
        }).then((res) => {
          let forms = res.data

          for (let i in forms) {
            let pic = forms[i].pic
            if (pic != null) {
              forms[i].pic = this.$constContent.imgUrl + pic
            }
            let time = forms[i].time;
            let date = new Date(time);
            res.data[i].time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", date)
          }
          let Cards=[]
          let Card={}
          Card.title=list[index]
          Card.id=0
          Card.content = res.data
          Cards.push(Card)
          ths.$nextTick(() => {
            if(index==1){
              ths.$refs.card.init(Cards)
            }else {
              ths.$refs.card0.init(Cards)
            }
          })
        })
      }
    },
    toPage(e){
      this.current=e
      this.getDataList()
    },

    toDetails(id){
      this.$router.push('/industryDetails?id='+id)
      this.$emit('getTitle','详情')
    }
  }
}
</script>

<style scoped>
.article {
  width: 98%;
  height: 250px;
  border-bottom: 1px solid #eeeeee;
  margin-right: 2%;
  margin-bottom: 20px;
}

.date {
  width: 20%;
  float: left;
  background-image: url("../../assets/image/backgroundColor.png");
  background-size: 100% 100%;
  color: #ffffff;
}

.content {
  width: calc(80% - 10px);
  float: left;
  margin-left: 10px;
}

.day {
  width: 100%;
  font-size: 20px;
  text-align: center;
  padding-top: 20px;
}

.year {
  width: 100%;
  margin: 3px auto;
  font-size: 8px;
  text-align: center;
  padding-bottom: 20px;
}

.point > li {
  list-style: disc !important;
}

.information ul li p {
  line-height: 24px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.article .content p {
  line-height: 24px;
  height: 72px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.content h3 {
  line-height: 25px;
  height: 50px;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.content a {
  color: #ff3300;
  font-width: 600;
  font-size: 14px;
}

img {
  margin-top: 10px;
  width: 100%;
}
</style>